import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter and Routes
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import CountriesSection from './components/CountriesSection';
import HowItWorksSection from './components/HowItWorksSection';
import UseCasesSection from './components/UseCasesSection';
import Footer from './components/Footer';
import About from './pages/About';
import Login from './pages/Login';
import Home from './App';

function App() {
const [currentPage, setCurrentPage] = useState('home');
const handleNavigateToPage = (page) => {
setCurrentPage(page);
};
const renderPage = () => {
switch (currentPage) {
case 'home':
return (
<>
    <HeroSection />
    <HowItWorksSection />
<CountriesSection />
<UseCasesSection />
</>
);
case 'about':
    return <About />;
  case 'home':
    return <Home />;
  case 'login':
    return <Login />;
default:
return Home;
}
};
return (
<div className="App">
<Router>
<Header onNavigate={handleNavigateToPage} />
<Routes>
        <Route path="/" element={renderPage()} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path= "/login" element={<Login />} />
</Routes>
<Footer />
</Router>
</div>
);
}
export default App;