import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
      <section className="HeroSection">
          <div className='Overlay'></div>
          <div className='HeroSectionContent'></div>
          <h1>Find trusted professionals<br/> recommended by real people</h1>
          <input type="text" placeholder="Search for professionals..." />
          <select>
              <option value="" disabled selected>Filter by</option>
              <option value="Most Recommended">Most Recommended</option>
              <option value="Country">Country</option>
          </select>
          <button className="search-button">Search</button>
    </section>
  );
}

export default HeroSection;
