import React from 'react';
import './CountriesSection.css';

function CountriesSection() {
  return (
    <section className="CountriesSection">
      <h2>Professionals Available in These Countries</h2>
      <div className="countries-grid">
        <div>USA (120)</div>
        <div>UK (85)</div>
        <div>Canada (60)</div>
        <div>Australia (45)</div>
      </div>
    </section>
  );
}

export default CountriesSection;

