import React from 'react';
import './UseCasesSection.css';

function UseCasesSection() {
  return (
    <section className="UseCasesSection">
      <h2>Use Cases</h2>
      <div className="use-cases">
        <div className="use-case">
          <h3>Consultants</h3>
          <p>Find top business consultants.</p>
        </div>
        <div className="use-case">
          <h3>Freelancers</h3>
          <p>Connect with skilled freelancers.</p>
        </div>
        <div className="use-case">
          <h3>Tutors</h3>
          <p>Hire experienced tutors.</p>
        </div>
        <div className="use-case">
          <h3>Medical Professionals</h3>
          <p>Get in touch with healthcare experts.</p>
        </div>
      </div>
    </section>
  );
}

export default UseCasesSection;

