import React, { useState } from 'react';
import './HowItWorksSection.css';

const HowItWorksSection = () => {
const [SeekingProfessionals, setSeekingProfessionals] = useState(true);
const FindProfessionals = () => (
<div>
<h2>How It Works</h2>
      <div className="steps">
        <div className="step">
          <h3>1. Find a Professional</h3>
          <p>Search for professionals based on your needs.</p>
        </div>
        <div className="step">
          <h3>2. Read Recommendations</h3>
          <p>View recommendations from real clients.</p>
        </div>
        <div className="step">
          <h3>3. Contact & Collaborate</h3>
          <p>Get in touch and start working together.</p>
        </div>
      </div>
</div>
);
const GetRecommended = () => (
<div>
<h2>How It Works</h2>
      <div className="steps">
        <div className="step">
          <h3>1. Find a Professional</h3>
          <p>Search for professionals based on your needs.</p>
        </div>
        <div className="step">
          <h3>2. Read Recommendations</h3>
          <p>View recommendations from real clients.</p>
        </div>
        <div className="step">
          <h3>3. Contact & Collaborate</h3>
          <p>Get in touch and start working together.</p>
        </div>
      </div>
</div>
);
return (
<section className='HowItWorksSection'>
<div>
<button onClick={() => setSeekingProfessionals(true)} className={`FindProfessionalsButton ${SeekingProfessionals ? 'active' : ''}`}>
Finding Professionals
</button>
<button onClick={() => setSeekingProfessionals(false)} className={`GetRecommendedButton ${!SeekingProfessionals ? 'active' : ''}`}>
Getting Recommended
</button>
</div>
{SeekingProfessionals ? <FindProfessionals /> : <GetRecommended />}
</section>
);
};
export default HowItWorksSection;