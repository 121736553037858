import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom'


    function Header() {
        
  return (
    <header className="Header">
      <div className="logo">VetHype</div>
      <nav>
      <Link to="/">Home</Link>
<Link to="/about">About</Link>
<a href="/login" className="login-link">Login</a>
<button className="cta-button">For Professionals</button>
      </nav>
    </header>
  );
}

export default Header;

