import React from 'react';
import './About.css';

function About() {

  const openLink = (url) => {
    window.open(url, 'https://forms.gle/QG1nsxZyanci1AVTA');
  };

  return (
    <div className="About">
      <h1>Welcome to Vethype!</h1>
      <p>My name is Jeff Ocaya, and I made this site. Before diving into the world of programming, I dabbled in forex trading.Once a friend sought my advice on the best forex broker. I recommended a Kenyan broker, a choice my brother had previously vouched for. This recommendation saved my friend considerable time and effort. </p>
      <p>But what if you want to verify the reputation of individuals you deal with, not just businesses? Traditional methods like reading reviews on platforms such as Trustpilot are great for businesses, but they fall short when it comes to individual professionals. The reality is that we engage with people, not just entities.</p>
      <p>The challenge is even more significant when it comes to hiring professional services. Social media can be misleading, full of likes and comments that don't necessarily reflect true capabilities. Google searches prioritize SEO, not genuine reputation. While there are countless directories, they mostly list businesses, not the individual professionals behind them. This leaves us relying on the age-old method of word-of-mouth recommendations.</p>
      <p>That's where VetHype comes in. We aim to bring the reliability of word-of-mouth recommendations into the digital age. VetHype is a directory of professionals, vouched for by the people who have worked with them. Our platform helps you make informed decisions based on real experiences and trusted testimonials.</p>
      <p>Thank you for visiting VetHype, where we connect you with reputable professionals you can trust.</p>
      
      <button className='joinButton' onClick={() => openLink('https://forms.gle/QG1nsxZyanci1AVTA') }>
        Join Waitlist
      </button>

    </div>
    
  );
}

export default About;
